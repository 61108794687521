// ChatView.js
import React, { useState } from "react";
import SubHeaderView from "./SubHeaderView";

function ChatView() {
  const [chatMessages, setChatMessages] = useState([]);

  const handleSinAtenderClick = () => {
    console.log(messages);
    // Simulación de consulta AJAX para obtener mensajes
    const messages = [
      { id: 1, text: "Mensaje 1" },
      { id: 2, text: "Mensaje 2" },
      { id: 3, text: "Mensaje 3" },
    ];
    setChatMessages(messages);
    
  };

  return (
    <div>
      <SubHeaderView handleSinAtenderClick={handleSinAtenderClick} />
      <div>
        {chatMessages.map((message) => (
          <div key={message.id}>{message.text}</div>
        ))}
      </div>
    </div>
  );
}

export default ChatView;
